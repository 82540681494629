import React, { useEffect, useState, useCallback } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Accordion,
  Grid,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Divider,
  Button,
  Box,
  CircularProgress,
} from '@material-ui/core';
import { darken } from 'polished';
import { ExpandMore } from '@material-ui/icons';
import Image from 'material-ui-image';
import clsx from 'clsx';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import FullScreenContent from '~/components/Conteudo/FullScreen';
import ModalAtividade from '~/components/Conteudo/ModalAtividade';
import ModalRespostasQuestionario from '~/components/Atividades/ModalRespostasQuestionario';

import IconPdf from '~/assets/ic_pdf.svg';
import IconLink from '~/assets/ic_link.svg';
import IconPodcast from '~/assets/ic_podcast.svg';
import IconVideo from '~/assets/ic_video.svg';
import IconInteractivo from '~/assets/ic_interactivo.svg';
import IconVideoAulas from '~/assets/ic_portal_aluno-18.svg';
import IconAtividade from '~/assets/ic_atividades.svg';

import { aulaChoosed } from '~/store/modules/videoaula/actions';
import { aulaChoosedSuccess } from '~/store/modules/aula/actions';
import { chooseAtividade } from '~/store/modules/questionario/actions';

import { usePauta } from '~/hooks/pauta';

import history from '~/services/history';
import api from '~/services/api';

const CustomCircularProgress = withStyles(() => ({
  root: {
    color: '#50A6FF',
    border: '1px solif #c1c1c1',
    boxShadow: `inset 0 0 0 ${(6 / 44) * 50}px #d1d1d1`,
    borderRadius: '50%',
  },
}))(CircularProgress);

const useStyles = makeStyles(() => ({
  qdtAulas: {
    color: '#4D5884',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '24px',
  },
  dataAula: {
    color: '#4D5884',
    fontSize: '14px',
    lineHeight: '24px',
  },
  grid: {
    display: 'flex',
    alignItems: 'center',
  },
  detalhesAcordeon: {
    background: 'rgba(189, 192, 212, 0.14)',
  },
  aula: {
    fontWeight: 'bold',
    fontSize: '17px',
    lineHeight: '20px',
    letterSpacing: '0.25px',
    color: '#50A6FF',
  },
  nomeAula: {
    fontSize: '17px',
    lineHeight: '20px',
    letterSpacing: '0.15px',
    color: '#4D5884',
    fontWeight: 500,
  },
  data: {
    fontSize: '12px',
    lineHeight: '24px',
    color: '#868CAB',
  },
  acessar: {
    background: '#50A6FF',
    borderRadius: '200px',
    color: '#fff',
    '&:hover': {
      background: darken(0.1, '#50A6FF'),
    },
  },
  accordionAula: {
    width: '100%',
  },
  conteudo: {
    display: 'flex',
    alignItems: 'center',
  },
  labelConteudo: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  colorVideo: {
    color: '#D5322E',
  },
  colorPdf: {
    color: '#6B41E0',
  },
  colorPodcast: {
    color: '#C14CD4',
  },
  colorLink: {
    color: '#777190',
  },
  colorInterativo: {
    color: '#256CFF',
  },
  colorAtividade: {
    color: '#FF5C7E',
  },
  colorVideoAula: {
    color: '#FF9239',
  },
  iconLabel: {
    fontSize: '11px',
    marginLeft: '5px',
    fontWeight: 'bold',
  },
  visualizado: {
    color: '#fff',
    background: '#47D19D',
    borderRadius: '4px',
    fontSize: '10px',
    padding: '5px 15px',
  },
  naoVisualizado: {
    color: '#fff',
    background: '#EC184B',
    borderRadius: '4px',
    fontSize: '10px',
    padding: '5px 15px',
  },
  dataPost: {
    color: '#868CAB',
    fontSize: '12px',
    margin: '10px 0',
  },
  labelProgress: {
    width: '90px',
    marginRight: '15px',
  },
  txtLabelProgress: {
    color: '#50A6FF',
    fontWeight: 700,
    textAlign: 'end',
  },
  numberProgress: {
    color: '#50A6FF',
    fontWeight: 700,
  },
}));

export default function PainelExpansivo({
  aula,
  materias,
  materiaEscolhida,
  contrato,
  conteudo,
  aulaActive,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { salvarPresenca } = usePauta();
  const videoAulas = useSelector((state) => state.videoaula.aulas);
  const [expanded, setExpanded] = useState(
    aula.aula_id === (aulaActive && Number(aulaActive))
  );
  const [openConteudo, setOpenConteudo] = useState(false);
  const [openFullScreen, setOpenFullScreen] = useState(false);
  const [openQuestionario, setOpenQuestionario] = useState(false);
  const [realizarAtividadeId, setRealizarAtividadeId] = useState(null);
  const [realizarAtividade, setRealizarAtividade] = useState(null);
  const [conteudoEscolhido, setConteudoEscolhido] = useState(false);
  const [conteudosAssistidos, setConteudosAssistidos] = useState(() => {
    try {
      return aula.conteudos_aula
        .filter(({ visualizado }) => visualizado)
        .reduce(
          (p, { smartconteudosmart_id }) => [...p, smartconteudosmart_id],
          []
        );
    } catch (error) {
      return [];
    }
  });
  const [watchProgress, setWatchProgress] = useState(null);

  const conteudosEad = ((conteudos, isEad) => {
    let value = [];
    if (isEad && Array.isArray(conteudos) && conteudos.length > 0) {
      value = conteudos.reduce(
        (p, { smartconteudosmart_id }) => [...p, smartconteudosmart_id],
        []
      );
    }
    return value;
  })(aula.conteudos_aula, aula.ead);

  const marcarPresenca = useCallback(async () => {
    const data = await salvarPresenca({
      turma: materiaEscolhida.turma_id,
      materia: materiaEscolhida.materia_id,
      aula: aula.aula_id,
      contrato: contrato.contrato_id,
    });

    if (data) {
      aula.data = data.data_aula;
    }
  }, [aula]); // eslint-disable-line

  useEffect(() => {
    if (
      !aula.data &&
      conteudosEad.length > 0 &&
      conteudosEad.length === conteudosAssistidos.length
    ) {
      marcarPresenca();
    }
  }, [aula, conteudosAssistidos]); //eslint-disable-line

  useEffect(() => {
    if (
      Array.isArray(conteudosAssistidos) &&
      watchProgress === null &&
      aula.conteudos_aula
    ) {
      setWatchProgress(
        (100 * conteudosAssistidos.length) / aula.conteudos_aula.length
      );
    }
  }, [conteudosAssistidos]); //eslint-disable-line

  const isConteudoAulaTipoAtividade = (conteudoAulaTipo) => {
    return conteudoAulaTipo === 15;
  };

  const getLabelQuantidadeConteudos = (quantidade) => {
    if (quantidade > 1) {
      return `${quantidade} conteúdos`;
    }

    return `${quantidade} conteúdo`;
  };

  const getTipoConteudo = (tipo) => {
    let icon = null;
    let classe = null;
    let label = null;

    switch (tipo) {
      case 1:
        icon = IconPdf;
        classe = classes.colorPdf;
        label = 'EBOOK';
        break;
      case 2:
        icon = IconVideo;
        classe = classes.colorVideo;
        label = 'VIDEOAULA';
        break;
      case 3:
        icon = IconPodcast;
        classe = classes.colorPodcast;
        label = 'PODCAST';
        break;
      case 4:
        icon = IconLink;
        classe = classes.colorLink;
        label = 'LINK';
        break;
      case 8:
        icon = IconInteractivo;
        classe = classes.colorInterativo;
        label = 'INTERATIVO';
        break;
      case 15:
        icon = IconAtividade;
        classe = classes.colorAtividade;
        label = 'ATIVIDADE';
        break;
      default:
        icon = IconVideoAulas;
        classe = classes.colorVideoAula;
        label = 'AULAS AO VIVO';
        break;
    }

    return (
      <div className={classes.labelConteudo}>
        <Image
          src={icon}
          style={{
            display: 'flex',
            paddingTop: 'unset',
            background: 'none',
          }}
          imageStyle={{
            width: 'unset',
            height: 'unset',
            position: 'unset',
            maxHeight: '50px',
          }}
        />
        <Typography component="p" className={clsx(classes.iconLabel, classe)}>
          {label}
        </Typography>
      </div>
    );
  };

  const handleClickVideoAula = (aulaEscolhida) => {
    let novaAulaEscolhida = { ...aulaEscolhida };
    if (!aulaEscolhida.aula) {
      novaAulaEscolhida = { ...aulaEscolhida, aula: aulaEscolhida.titulo };
    }
    dispatch(aulaChoosed(novaAulaEscolhida));
    history.push('/video-aulas?conteudo=1');
  };

  const getAtividadeLancada = async (lancarAtividadeId) => {
    const response = await api.get(
      `atividades-realizadas/?atividadeLancada=${lancarAtividadeId}`
    );

    if (response.data[0] && !response.data[0].visualizado) {
      const { atividade_id, lancaratividade_id } = response.data[0];
      await api.post('atividade-visualizada', {
        atividade_id,
        lancaratividade_id,
      });
    }

    return response.data;
  };

  const showConteudo = async (conteudoAula) => {
    setConteudoEscolhido(conteudoAula);

    if (!conteudoAula.visualizado) {
      await api.put(
        `conteudo/status/${
          conteudoAula.smartconteudosmart_id || conteudoAula.smartconteudo_id
        }`
      );
      conteudoAula.visualizado = true;
    }

    if (isConteudoAulaTipoAtividade(conteudoAula.tipo)) {
      const atividadeLancada = await getAtividadeLancada(
        conteudoAula.smartconteudosmart_id
      );

      if (
        !aula.data &&
        conteudosEad.length > 0 &&
        !conteudosAssistidos.includes(conteudoAula.smartconteudosmart_id)
      ) {
        setConteudosAssistidos([
          ...conteudosAssistidos,
          conteudoAula.smartconteudosmart_id,
        ]);
      }

      if (atividadeLancada[0].questionario) {
        if (atividadeLancada[0].realizaratividade_id) {
          setRealizarAtividade(atividadeLancada[0]);
          setRealizarAtividadeId(atividadeLancada[0].realizaratividade_id);
          setOpenQuestionario(true);
        } else {
          dispatch(chooseAtividade(atividadeLancada[0]));
          history.push(`/questionario/${conteudoAula.smartconteudosmart_id}`);
        }
      } else {
        setOpenConteudo(true);
      }
    } else {
      setOpenFullScreen(true);
    }

    dispatch(aulaChoosedSuccess(conteudoAula));
  };

  return (
    <>
      {!conteudo && (
        <>
          <AccordionDetails
            className={classes.detalhesAcordeon}
            key={aula.aula_id}
          >
            <Accordion
              elevation={0}
              className={classes.accordionAula}
              expanded={expanded}
              onChange={() => setExpanded(!expanded)}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                className={classes.detalhesAcordeon}
              >
                <Grid container>
                  <Grid item xs={12} sm={6}>
                    <Typography component="p" className={classes.aula}>
                      {aula.aula}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={2} className={classes.grid}>
                    <Typography component="p" className={classes.dataAula}>
                      {aula.data}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={2} className={classes.grid}>
                    <Typography component="p" className={classes.qdtAulas}>
                      {(conteudo && '1 aula') ||
                        (aula.conteudos_aula &&
                          getLabelQuantidadeConteudos(
                            aula.conteudos_aula.length
                          )) ||
                        '-'}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={2} className={classes.grid}>
                    <div className={classes.labelProgress}>
                      <Typography
                        variant="body2"
                        className={classes.txtLabelProgress}
                      >
                        Conteúdos Visualizados
                      </Typography>
                    </div>
                    <Box position="relative" display="inline-flex">
                      <CustomCircularProgress
                        variant="determinate"
                        value={watchProgress || 0}
                        size={50}
                        thickness={6}
                        disableShrink
                        classes={{
                          circle: classes.circle,
                        }}
                      />
                      <Box
                        top={0}
                        left={0}
                        bottom={0}
                        right={0}
                        position="absolute"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Typography
                          variant="caption"
                          component="div"
                          className={classes.numberProgress}
                        >
                          {watchProgress}%
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </AccordionSummary>
              {aula.conteudos_aula &&
                aula.conteudos_aula
                  .filter((conteudoAula) => conteudoAula.tipo !== null)
                  .map((conteudoAula) => (
                    <AccordionDetails
                      key={conteudoAula.smartconteudo_id}
                      className={classes.detalhesAcordeon}
                    >
                      <Grid container className={classes.conteudo}>
                        <Grid
                          item
                          xs={12}
                          sm={2}
                          className={classes.iconConteudo}
                        >
                          {getTipoConteudo(conteudoAula.tipo)}
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <Typography
                            component="p"
                            className={classes.nomeAula}
                            dangerouslySetInnerHTML={{
                              __html: conteudoAula.descricao.replace(
                                /(?:\r\n|\t|\r|\n)/g,
                                '<br />'
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                          <Typography
                            variant="caption"
                            component="label"
                            className={clsx(
                              conteudoAula.visualizado
                                ? classes.visualizado
                                : classes.naoVisualizado
                            )}
                          >
                            {conteudoAula.visualizado
                              ? 'VISUALIZADO'
                              : 'NÃO VISUALIZADO'}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                          <Typography variant="caption" component="label">
                            {conteudoAula.data || '-'}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                          <Button
                            type="button"
                            className={classes.acessar}
                            variant="contained"
                            fullWidth
                            onClick={() => {
                              showConteudo(conteudoAula);
                            }}
                          >
                            ACESSAR
                          </Button>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  ))}

              {!!videoAulas &&
                videoAulas
                  .filter((videoAula) => videoAula.aula_id === aula.aula_id)
                  .map((videoAula) => (
                    <AccordionDetails
                      key={videoAula.videoaula_id}
                      className={classes.detalhesAcordeon}
                    >
                      <Grid container className={classes.conteudo}>
                        <Grid
                          item
                          xs={12}
                          sm={2}
                          className={classes.iconConteudo}
                        >
                          {getTipoConteudo('video-aula')}
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <Typography
                            component="p"
                            className={classes.nomeAula}
                            dangerouslySetInnerHTML={{
                              __html: videoAula.descricao.replace(
                                /(?:\r\n|\t|\r|\n)/g,
                                '<br />'
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                          <Typography
                            variant="caption"
                            component="label"
                            className={clsx(
                              videoAula.visualizado
                                ? classes.visualizado
                                : classes.naoVisualizado
                            )}
                          >
                            {videoAula.visualizado
                              ? 'VISUALIZADO'
                              : 'NÃO VISUALIZADO'}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                          <Typography variant="caption" component="label">
                            {videoAula.data}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                          <Button
                            type="button"
                            className={classes.acessar}
                            variant="contained"
                            fullWidth
                            onClick={() => {
                              handleClickVideoAula(videoAula);
                            }}
                          >
                            ACESSAR
                          </Button>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  ))}
            </Accordion>
          </AccordionDetails>
          <Divider variant="fullWidth" />
        </>
      )}

      {conteudo && (
        <>
          <AccordionDetails
            key={aula.smartconteudo_id}
            className={classes.detalhesAcordeon}
          >
            <Grid container className={classes.conteudo}>
              <Grid item xs={12} sm={2} className={classes.iconConteudo}>
                {getTipoConteudo(aula.tipo)}
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography
                  component="p"
                  className={classes.nomeAula}
                  dangerouslySetInnerHTML={{
                    __html: aula.descricao.replace(
                      /(?:\r\n|\t|\r|\n)/g,
                      '<br />'
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={2} className={classes.data}>
                <Typography
                  variant="caption"
                  component="label"
                  className={clsx(
                    aula.visualizado
                      ? classes.visualizado
                      : classes.naoVisualizado
                  )}
                >
                  {aula.visualizado ? 'VISUALIZADO' : 'NÃO VISUALIZADO'}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography className={classes.dataPost} component="label">
                  {(aula.data &&
                    `Postado dia ${format(new Date(aula.data), 'dd/MM/yyyy', {
                      locale: ptBR,
                    })}`) ||
                    '-'}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Button
                  type="button"
                  className={classes.acessar}
                  variant="contained"
                  fullWidth
                  onClick={() => {
                    showConteudo(conteudo);
                  }}
                >
                  ACESSAR
                </Button>
              </Grid>
              {/* Para que é esse fullscreen? e pq não tinha o botão para acesso?
              <Grid item xs={12} sm={2}>
                <FullScreenContent
                  conteudo={aula}
                  contrato={contrato}
                  materiaEscolhida={materiaEscolhida}
                  materias={materias}
                />
              */}
            </Grid>
          </AccordionDetails>
          <Divider variant="fullWidth" />
        </>
      )}
      {openFullScreen && conteudoEscolhido && (
        <FullScreenContent
          contrato={contrato}
          materiaEscolhida={materiaEscolhida}
          materias={materias}
          open={openFullScreen}
          setOpen={setOpenFullScreen}
        />
      )}
      {openConteudo && conteudoEscolhido && (
        <ModalAtividade
          atividade={conteudoEscolhido}
          open={openConteudo}
          setOpen={setOpenConteudo}
        />
      )}
      {openQuestionario && realizarAtividadeId && (
        <ModalRespostasQuestionario
          lancarAtividadeId={conteudoEscolhido.smartconteudosmart_id}
          realizarAtividadeId={realizarAtividadeId}
          atividade={realizarAtividade}
          open={openQuestionario}
          setOpen={setOpenQuestionario}
        />
      )}
    </>
  );
}

PainelExpansivo.propTypes = {
  aula: PropTypes.shape().isRequired,
  materiaEscolhida: PropTypes.shape().isRequired,
  materias: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  contrato: PropTypes.shape().isRequired,
  conteudo: PropTypes.bool,
  aulaActive: PropTypes.number,
};

PainelExpansivo.defaultProps = {
  conteudo: false,
  aulaActive: null,
};

import { all, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';
import { apiCallHandler } from '~/services/callWrapper';
import {
  loadBillingError,
  loadBillingSuccess,
  loadInstallmentsError,
  loadInstallmentsSuccess,
} from './actions';

export function* loadBilling({ payload }) {
  try {
    const response = yield apiCallHandler(
      api.get,
      `/receber/${payload.receber_id}/gerar-cobranca`
    );

    yield put(loadBillingSuccess(response.data));

    setTimeout(() => {
      window.open(response.data.url, '_blank');
    });
  } catch (err) {
    alert(err.message); // eslint-disable-line
    if (err.response && err.response.data) {
      yield put(loadBillingError());
      toast.error('Erro ao gerar pagamento, favor contactar o suporte');
    }
  }
}

export function* loadInstallments({ payload }) {
  try {
    const response = yield apiCallHandler(
      api.get,
      `parcelas/${payload.contrato_id}`
    );

    const pagas = response.data.filter((parcela) => parcela.pago === true);
    const abertas = response.data.filter((parcela) => parcela.pago === false);
    if (abertas.length) {
      abertas.sort((a, b) => a.receber_id - b.receber_id);
      abertas[0].older = true;
    }
    const cache = new Date();

    yield put(loadInstallmentsSuccess({ pagas, abertas, cache }));
  } catch (err) {
    console.log(err);
    yield put(loadInstallmentsError());
    toast.error('Erro ao carregar pagamentos, favor contactar o suporte');
  }
}

export default all([
  takeLatest('@financeiro/LOAD_BILLING', loadBilling),
  takeLatest('@financeiro/LOAD_INSTALLMENTS', loadInstallments),
]);

import produce from 'immer';
import BackgroundDefault from '~/assets/default-background.png';

const INITIAL_STATE = {
  data: {
    nome: 'default',
    logo: 'default.png',
    logo_header: 'default-header.png',
    primary_color: '#f97200',
    background: BackgroundDefault,
    politicas_privacidade: 'https://www.f10.com.br/politica-de-privacidade',
    fale_conosco: true,
    grupo: 1,
  },
};

export default function instituicao(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@instituicao/LOAD_SUCCESS': {
        draft.data = action.payload.instituicao;
        break;
      }
      default:
    }
  });
}

import DefaultLogo from '~/assets/logos/default.png';
import DefaultHeader from '~/assets/logos/default-header.png';
import DefaultBackground from '~/assets/default-background.png';

const configuracoes = [
  {
    default_primary_color: '#f97200',
    default_id: 1,
    default_politicas_privacidade:
      'https://www.f10.com.br/politica-de-privacidade',
    default_fale_conosco: true,
  },
  {
    saga_primary_color: '#f15a24',
    saga_id: 18,
    saga_politicas_privacidade:
      'https://saga.art.br/termos-de-uso-e-politica-de-privacidade',
    saga_fale_conosco: false,
  },
  {
    cebrac_primary_color: '#002f62',
    cebrac_id: 2,
    cebrac_politicas_privacidade:
      'https://www.cebrac.com.br/politica_privacidade',
    cebrac_fale_conosco: true,
  },
  {
    escolaepic_primary_color: '#0932fe',
    escolaepic_id: 139,
    escolaepic_politicas_privacidade:
      'http://epicschool.com.br/politica-de-privacidade',
    escolaepic_fale_conosco: true,
  },
  {
    kindo_primary_color: '#603a8e',
    kindo_id: 1,
    kindo_politicas_privacidade:
      'https://www.f10.com.br/politica-de-privacidade',
    kindo_fale_conosco: true,
  },
  {
    ipacursosonline_primary_color: '#00903B',
    ipacursosonline_id: 15,
    ipacursosonline_politicas_privacidade:
      'https://www.ipaidiomasonline.com/politicadeprivacidade',
    ipacursosonline_fale_conosco: true,
  },
  {
    iinttec_primary_color: '#03128c',
    iinttec_id: 143,
    iinttec_politicas_privacidade:
      'http://intecsaopaulo.com/politicas-de-privacidade',
    iinttec_fale_conosco: true,
  },
  {
    luxmodels_primary_color: '#B8936B',
    luxmodels_id: 145,
    luxmodels_politicas_privacidade:
      'https://luxmodels.com.br/politica-de-privacidade',
    luxmodels_fale_conosco: true,
  },
  {
    efac_primary_color: '#B8936B',
    efac_id: 133,
    efac_politicas_privacidade: 'https://efac.com.br/politica-de-privacidade',
    efac_fale_conosco: true,
  },
  {
    futurepro_primary_color: '#0A036C',
    futurepro_id: 147,
    futurepro_politicas_privacidade:
      'https://portalfuture.com.br/politica-privacidade',
    futurepro_fale_conosco: true,
  },
  {
    escolavoxel_primary_color: '#00ABCD',
    escolavoxel_id: 48,
    escolavoxel_politicas_privacidade:
      'https://escolavoxel.com.br/politica-de-privacidade',
    escolavoxel_fale_conosco: true,
  },
  {
    escolad_primary_color: '#434142',
    escolad_id: 148,
    escolad_politicas_privacidade:
      'https://escola-d.com.br/politica-de-privacidade',
    'escola-d_fale_conosco': true,
  },
  {
    institutoanahickmann_primary_color: '#62b2af',
    institutoanahickmann_id: 144,
    institutoanahickmann_politicas_privacidade: '',
    institutoanahickmann_fale_conosco: true,
  },
  {
    bslidiomas_primary_color: '#062d7c',
    bslidiomas_id: 152,
    bslidiomas_politicas_privacidade:
      'https://app.bslidiomas.com.br/view/sobre/politicas.php',
    bslidiomas_fale_conosco: true,
  },
  {
    grupocapacitar_primary_color: '#EE3C39',
    grupocapacitar_id: 150,
    grupocapacitar_politicas_privacidade:
      'https://www.grupocapacitar.net.br/politica-de-privacidade',
    grupocapacitar_fale_conosco: true,
  },
  {
    zion_primary_color: '#f97200',
    zion_id: 24,
    zion_politicas_privacidade:
      'https://escolazion.com/politica-de-privacidade/',
    zion_fale_conosco: true,
  },
  {
    unibe_primary_color: '#213b13',
    unibe_id: 62,
    unibe_politicas_privacidade:
      'https://unibe.com.br/politica-de-privacidade/',
    unibe_fale_conosco: true,
  },
  {
    hescursos_primary_color: '#33334b',
    hescursos_id: 126,
    hescursos_politicas_privacidade:
      'http://www.hescursos.com.br/sobre-a-hes-cursos-profissionalizantes-graduacao-pos-jau-sp/',
    hescursos_fale_conosco: true,
  },
  {
    microcamp_primary_color: '#77a338',
    microcamp_id: 171,
    microcamp_politicas_privacidade:
      'https://microcamp.com.br/politica-de-privacidade',
    microcamp_fale_conosco: true,
  },
  {
    f10_primary_color: '#ed6500',
    f10_id: 1,
    f10_politicas_privacidade: 'https://www.f10.com.br/politica-de-privacidade',
    f10_fale_conosco: true,
  },
  {
    clubpro_primary_color: '#fd5b15',
    clubpro_id: 193,
    clubpro_politicas_privacidade:
      'https://clubpro.com.br/politica-de-privacidade',
    clubpro_fale_conosco: true,
  },
  {
    cesccursos_primary_color: '#ffffff',
    cesccursos_id: 138,
    cesccursos_politicas_privacidade:
      'https://cesccursos.com.br/politica-de-privacidade/',
    cesccursos_fale_conosco: true,
  },
  {
    escolamvp_primary_color: '#1A1A1A',
    escolamvp_id: 198,
    escolamvp_politicas_privacidade:
      'https://escolamvp.com.br/politica-de-privacidade/',
    escolamvp_fale_conosco: true,
  },
  {
    voxis_primary_color: '#29FF64',
    voxis_id: 207,
    voxis_politicas_privacidade:
      'https://voxis.art.br/politica-de-privacidade/',
    voxis_fale_conosco: true,
  },
  {
    siepbrasil_primary_color: '#015697',
    siepbrasil_id: 178,
    siepbrasil_politicas_privacidade: 'https://siepbrasil.com.br/privacy.pdf',
    siepbrasil_fale_conosco: true,
  },
  {
    escolaalmeidasantos_primary_color: '#022E6E',
    escolaalmeidasantos_id: 177,
    escolaalmeidasantos_politicas_privacidade:
      'https://escolaalmeidasantos.com.br/politicas',
    escolaalmeidasantos_fale_conosco: true,
  },
  {
    escolamegamente_primary_color: '#7F4296',
    escolamegamente_id: 204,
    escolamegamente_politicas_privacidade:
      'https://escolamegamente.com.br/politicasdeprivacidade/',
    escolamegamente_fale_conosco: true,
  },
  {
    portalunipro_primary_color: '#7F4296',
    portalunipro_id: 199,
    portalunipro_politicas_privacidade:
      'https://www.f10.com.br/politica-de-privacidade/',
    portalunipro_fale_conosco: true,
  },
  {
    smartcentrodeensino_primary_color: '#000000',
    smartcentrodeensino_id: 211,
    smartcentrodeensino_politicas_privacidade:
      'https://www.smartcentrodeensino.com.br/pol%C3%ADtica-de-privacidade',
    smartcentrodeensino_fale_conosco: true,
  },
  {
    xtreme_primary_color: '#0C76F4',
    xtreme_id: 210,
    xtreme_politicas_privacidade: 'https://www.xtreme.cx/',
    xtreme_fale_conosco: true,
  },
  {
    prismatreinamentos_primary_color: '#31E2E9',
    prismatreinamentos_id: 209,
    prismatreinamentos_politicas_privacidade: '',
    prismatreinamentos_fale_conosco: true,
  },
  {
    iamedtech_primary_color: '#46210F',
    iamedtech_id: 164,
    iamedtech_politicas_privacidade: '',
    prismatreinamentos_fale_conosco: true,
  },
  {
    simportal_primary_color: '#600373',
    simportal_id: 172,
    simportal_politicas_privacidade: '',
    simportal_fale_conosco: true,
  },
  {
    fcooffshore_primary_color: '#16243E',
    fcooffshore_id: 215,
    fcooffshore_politicas_privacidade:
      'https://fcooffshore.wbuy.com.br/politica-privacidade/',
    fcooffshore_fale_conosco: true,
  },
  {
    INAQ_primary_color: '#FF8200',
    INAQ_id: 213,
    INAQ_politicas_privacidade:
      'https://institutoinaq.com.br/politica-de-privacidade/',
    INAQ_fale_conosco: true,
  },
  {
    ifpcursos_primary_color: '#8c161a',
    ifpcursos_id: 117,
    ifpcursos_politicas_privacidade:
      'https://www.ifpcursos.com.br/politica-privacidade-app',
    ifpcursos_fale_conosco: true,
  },
  {
    equilibriumcursos_primary_color: '#8c161a',
    equilibriumcursos_id: 218,
    equilibriumcursos_politicas_privacidade: '',
    equilibriumcursos_fale_conosco: true,
  },
  {
    ifpbrasilcursos_primary_color: '#004de1',
    ifpbrasilcursos_id: 117,
    ifpbrasilcursos_politicas_privacidade: '',
    ifpbrasilcursos_fale_conosco: true,
  },
];

export const getConfigurations = async (schoolName) => {
  let primary_color = '#f97200';
  let politicas_privacidade = 'https://www.f10.com.br/politica-de-privacidade';
  let fale_conosco = true;
  let grupo = 1;

  // eslint-disable-next-line
  configuracoes.map((configuration) => {
    if (configuration[`${schoolName}_primary_color`]) {
      primary_color = configuration[`${schoolName}_primary_color`];
      politicas_privacidade =
        configuration[`${schoolName}_politicas_privacidade`];
      fale_conosco = configuration[`${schoolName}_fale_conosco`];
      grupo = configuration[`${schoolName}_id`];
    }
  });

  const logos = require.context('../assets/logos', true);
  let imageList = logos.keys().map((image) => logos(image));

  const logo = imageList.find((image) => image.includes(`${schoolName}.`));

  const logoHeader = imageList.find((image) =>
    image.includes(`${schoolName}-header`)
  );

  const backgrounds = require.context('../assets', true);
  imageList = backgrounds.keys().map((image) => backgrounds(image));

  const background = imageList.find(
    (image) =>
      (typeof image === 'string' || image instanceof String) &&
      image.includes(`${schoolName}-background`)
  );

  return {
    nome: schoolName,
    logo: `${schoolName}.png`,
    logo_full_path: logo || DefaultLogo,
    logo_header: logoHeader || DefaultHeader,
    background: background || DefaultBackground,
    primary_color,
    politicas_privacidade,
    fale_conosco,
    grupo,
  };
};

export function signInRequest(login, password, accessToken = null, grupo) {
  return {
    type: '@auth/SIGN_IN_REQUEST',
    payload: { login, password, accessToken, grupo },
  };
}

export function signInSuccess(token, aluno) {
  return {
    type: '@auth/SIGN_IN_SUCCESS',
    payload: { token, aluno },
  };
}

export function signInSuccessSetUserId(id) {
  return {
    type: '@auth/SIGN_IN_SUCCESS_SET_USERID',
    payload: { id },
  };
}

export function signFailure() {
  return {
    type: '@auth/SIGN_FAILURE',
  };
}

export function signOut() {
  return {
    type: '@auth/SIGN_OUT',
  };
}

export function reHydrateSuccess() {
  return {
    type: '@auth/REHYDRATE_SUCCESS',
  };
}

import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { darken } from 'polished';
import { makeStyles } from '@material-ui/core/styles';
import {
  Badge,
  Typography,
  Grid,
  IconButton,
  Link,
  Button as MuiButton,
} from '@material-ui/core';
import { FilterList } from '@material-ui/icons';
import CommentOutlined from '@material-ui/icons/CommentOutlined';
// import AttachmentOutlined from '@material-ui/icons/AttachmentOutlined';
// import Done from '@material-ui/icons/Done';
import DataGrid, {
  Column,
  Grouping,
  GroupPanel,
  Button,
  SearchPanel,
  Paging,
} from 'devextreme-react/data-grid';
import { format } from 'date-fns';
import ptBr from 'date-fns/locale/pt';
import clsx from 'clsx';

import api from '~/services/api';
import history from '~/services/history';

import { aulaChoosed } from '~/store/modules/videoaula/actions';

import Breadcrumb from '~/components/CustomUI/CustomBreadcrumbs';
import ModalComentarios from '~/components/Atividades/ModalComentarios';
import ModalRespostasQuestionario from '~/components/Atividades/ModalRespostasQuestionario';
import ModalAtividadeVideoaula from '~/components/VideoAulas/Atividades/ModalAtividade';
import Loading from '~/components/Loading';
import Filtros from '~/components/Filtros';
import FiltrosAtividades from '~/components/Atividades/FiltrosAtividades';
import { apiCallHandler } from '~/services/callWrapper';
import { chooseAtividade } from '~/store/modules/questionario/actions';

const useStyles = makeStyles({
  tituloFuncionalidade: {
    textTransform: 'uppercase',
    color: '#3E98C7',
    fontWeight: 'bold',
    marginBottom: '30px',
  },
  comAnexo: {
    color: '#50A6FF',
  },
  corDefault: {
    color: '#4D5884',
  },
  badge: {
    '& > .MuiBadge-anchorOriginTopRightRectangle': {
      top: 0,
      right: '-2px',
    },
  },
  grids: {
    marginTop: '15px',
  },
  showFiltros: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  divLinkAula: {
    display: 'flex',
    alignItems: 'center',
  },
  linkAula: {
    marginLeft: '5px',
    color: '#50A6FF',
    textDecorationLine: 'underline',
  },
  acessarAnexo: {
    background: '#50A6FF',
    borderRadius: '200px',
    color: '#fff',
    transition: 'background 0.4s',
    marginLeft: '10px',
    '&:hover': {
      background: darken(0.1, '#50A6FF'),
    },
  },
  labelStatus: {
    padding: '0 8px',
    background: '#50A6FF',
    color: '#fff',
  },
  spanStatus: {
    padding: '2px',
    color: '#fff',
    minWidth: '86px',
    width: 'fit-content',
    borderRadius: '6px',
    display: 'flex',
    justifyContent: 'center',
  },
  statusPendente: {
    background: '#256CFF',
  },
  statusFechada: {
    background: '#BDC0D4',
  },
  statusEntregue: {
    background: '#9B00FA',
  },
  statusAvaliada: {
    background: '#4EC87F',
  },
  statusFazer: {
    background: '#F18500',
  },
});

export default function Atividades() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [openComentarios, setOpenComentarios] = useState(false);
  const [openModalAnexo, setOpenModalAnexo] = useState(false);
  const [openModalRespostas, setOpenModalRespostas] = useState(false);
  const [atividade, setAtividade] = useState(null);
  const [atividades, setAtividades] = useState(null);
  const [showFiltros, setShowFiltros] = useState(true);
  const [showLoading, setShowLoading] = useState(false);
  const [filtros, setFiltros] = useState({
    data: undefined,
    curso: undefined,
    materia: undefined,
  });

  const STATUS_ABERTA = 1;
  const STATUS_ENCERRADA = 2;
  const STATUS_PENDENTE = 3;
  const STATUS_ENTREGUE = 4;
  const STATUS_FAZER_NOVAMENTE = 5;
  const STATUS_AVALIADO = 6;
  const STATUS_FECHADO = 7;

  function handleCloseModalAnexo() {
    setOpenModalAnexo(false);
    setAtividade(null);
  }

  const removeTimezoneDate = (date) => {
    const dateFormatted = date.replace(/z/i, '');
    return dateFormatted;
  };

  const handleClickVerAula = async (data) => {
    if (!data.conteudos_aula) {
      setShowLoading(true);

      const response = await apiCallHandler(api.get, 'video-aulas', {
        params: { videoaula: data.videoaula },
      });

      setShowLoading(false);

      dispatch(aulaChoosed(response.data[0]));
      history.push('/video-aulas');
    } else {
      history.push(
        `conteudo/${data.contrato_id}/?materia=${data.materia_id}&aula=${data.aula_id}&atividadeLancada=${data.lancaratividade_id}`
      );
    }
  };

  const getGridCellData = (gridData) =>
    gridData.data[gridData.column.caption.toLowerCase()];

  const LinkCell = (cellData) => {
    const gridCellData = getGridCellData(cellData);
    return (
      <div className={classes.divLinkAula}>
        {gridCellData}
        <Link
          component="button"
          variant="body2"
          href="#"
          onClick={() => handleClickVerAula(cellData)}
          className={classes.linkAula}
        >
          Ver aula
        </Link>
      </div>
    );
  };

  function prepareCell(e) {
    if (e.rowType === 'data') {
      if (e.column.caption === 'DATA') {
        let dataFormatada = '-';
        if (e.value || e.data.data_entrega) {
          dataFormatada = format(
            new Date(removeTimezoneDate(e.value || e.data.data_entrega)),
            'dd/MM/yyyy',
            {
              locale: ptBr,
            }
          );
        }
        e.cellElement.innerText = dataFormatada;
      }
      if (e.column.caption === 'PRAZO') {
        let dataFormatada = '-';
        if (e.value && !e.data.conteudos_aula) {
          dataFormatada = format(
            new Date(removeTimezoneDate(e.value)),
            'dd/MM/yyyy',
            {
              locale: ptBr,
            }
          );
        }
        e.cellElement.innerText = dataFormatada;
      }
      if (e.column.caption === 'MATÉRIA') {
        e.cellElement.innerText = e.value || '-';
      }
    }
  }

  useEffect(() => {
    async function getAtividades() {
      setShowLoading(true);
      const response = await apiCallHandler(api.get, 'atividades-realizadas', {
        params: filtros,
      });
      setAtividades(response.data);
      setShowLoading(false);
    }

    getAtividades();
  }, [filtros]);

  const handleChangeShowFiltros = () => {
    setShowFiltros(!showFiltros);
  };

  function handleChangeFiltroData(novoFiltro) {
    setFiltros({ ...filtros, ...novoFiltro });
  }

  function handleChangeFiltrosSelect(novosFiltros) {
    const { curso, materia } = novosFiltros;
    setFiltros({ ...filtros, curso, materia });
  }

  const respostaDisponivel = (rowAtividade) => {
    return (
      !rowAtividade.status_id ||
      [STATUS_ABERTA, STATUS_PENDENTE, STATUS_FAZER_NOVAMENTE].includes(
        rowAtividade.status_id
      )
    );
  };

  const renderGridCell = (cellInfo) => {
    let classeColor = classes.statusPendente;

    if ([STATUS_FECHADO, STATUS_ENCERRADA].includes(cellInfo.data.status_id)) {
      classeColor = classes.statusFechada;
    }
    if ([STATUS_FAZER_NOVAMENTE].includes(cellInfo.data.status_id)) {
      classeColor = classes.statusFazer;
    }
    if ([STATUS_ENTREGUE].includes(cellInfo.data.status_id)) {
      classeColor = classes.statusEntregue;
    }
    if ([STATUS_AVALIADO].includes(cellInfo.data.status_id)) {
      classeColor = classes.statusAvaliada;
    }

    return (
      <>
        {cellInfo.value && (
          <Typography
            variant="caption"
            component="div"
            className={clsx(classes.spanStatus, classeColor)}
          >
            {cellInfo.value}
          </Typography>
        )}
      </>
    );
  };

  return (
    <>
      {showLoading && <Loading />}
      <Breadcrumb categorias={['Acadêmico']} funcionalidade="Atividades" />
      <Grid container>
        <Grid item xs={6}>
          <Typography variant="h6" className={classes.tituloFuncionalidade}>
            Atividades de aula
          </Typography>
        </Grid>
        <Grid item xs={6} className={classes.showFiltros}>
          <MuiButton
            size="small"
            type="button"
            onClick={handleChangeShowFiltros}
            className={classes.btShowFiltros}
          >
            <FilterList /> Filtros Avançados
          </MuiButton>
        </Grid>
      </Grid>
      {showFiltros && (
        <Filtros
          dateFilterEnable
          returnFiltros={handleChangeFiltroData}
          filtrosAplicados={filtros}
          form={
            <FiltrosAtividades
              returnFiltros={handleChangeFiltrosSelect}
              filtrosAplicados={filtros}
            />
          }
        />
      )}
      <Grid item xs={12} className={classes.grids}>
        <DataGrid
          dataSource={atividades}
          allowColumnReordering
          showBorders
          onCellPrepared={(e) => {
            prepareCell(e);
          }}
          noDataText="Sem atividade realizada"
          width="100%"
          columnAutoWidth={false}
          columnWidth="12.5%"
        >
          <GroupPanel visible />
          <SearchPanel visible />
          <Grouping autoExpandAll={false} />
          <Paging defaultPageSize={100} />

          {/* <Column dataField="contrato_id" visible={false} /> */}
          <Column caption="TÍTULO" dataField="atividade" dataType="string" />
          <Column caption="MATÉRIA" dataField="materia" dataType="string" />
          <Column
            caption="AULA"
            dataField="aula"
            cellRender={LinkCell}
            width="20%"
          />
          <Column caption="DATA" dataField="inicio" dataType="string" />
          <Column caption="PRAZO" dataField="prazo" dataType="string" />
          <Column
            caption="STATUS"
            dataField="status"
            dataType="string"
            cellRender={renderGridCell}
          />
          {/* <Column
            caption="ANEXOS"
            dataField="anexos"
            type="buttons"
            visible={false}
          >
            <Button
              render={(e) => {
                const temNota = e.data.nota && e.data.nota !== '-';
                const temAnexo = !!e.data.anexo;
                const classe = temAnexo ? classes.comAnexo : classes.corDefault;

                return (
                  <IconButton
                    disabled={temNota}
                    onClick={() => {
                      if (!temNota) {
                        setAtividade(e.data);
                        setOpenModalAnexo(true);
                      }
                    }}
                  >
                    {(!temNota && (
                      <AttachmentOutlined className={classe} />
                    )) || <Done className={classes.corDefault} />}
                  </IconButton>
                );
              }}
              className={classes.button}
            />
          </Column> */}
          <Column caption="NOTA" dataField="nota" dataType="string" />
          <Column type="buttons">
            <Button
              render={(e) => {
                const atividadeFechada = e.data.status_id === STATUS_FECHADO;
                const atividadeAvaliadda = e.data.status_id === STATUS_AVALIADO;
                const hasNotificacao =
                  e.data.hasnewcomment && e.data.hasnewcomment !== 'false';
                const classe =
                  !atividadeFechada && !atividadeAvaliadda
                    ? classes.comAnexo
                    : classes.corDefault;

                return (
                  <>
                    <IconButton
                      disabled={atividadeFechada || atividadeAvaliadda}
                      onClick={() => {
                        if (!atividadeFechada && !atividadeAvaliadda) {
                          setAtividade(e.data);
                          setOpenComentarios(true);
                        }
                      }}
                      className={classe}
                    >
                      <Badge
                        color="error"
                        variant="dot"
                        className={classes.badge}
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        invisible={!hasNotificacao}
                      >
                        <CommentOutlined />
                      </Badge>
                    </IconButton>
                    <MuiButton
                      variant="contained"
                      className={classes.acessarAnexo}
                      onClick={() => {
                        if (respostaDisponivel(e.data)) {
                          if (e.data.questionario) {
                            if (STATUS_ENTREGUE === e.data.status_id) {
                              setAtividade(e.data);
                              setOpenModalRespostas(true);
                            } else {
                              dispatch(chooseAtividade(e.data));
                              history.push(
                                `/questionario/${e.data.lancaratividade_id}`
                              );
                            }
                          } else {
                            setAtividade(e.data);
                            setOpenModalAnexo(true);
                          }
                        }
                      }}
                      disabled={!respostaDisponivel(e.data)}
                    >
                      ACESSAR
                    </MuiButton>
                  </>
                );
              }}
              className={classes.button}
            />
          </Column>
        </DataGrid>
      </Grid>
      {atividade && openComentarios && (
        <ModalComentarios
          atividade={atividade}
          open={openComentarios}
          handleClose={setOpenComentarios}
        />
      )}
      {atividade && openModalAnexo && (
        <ModalAtividadeVideoaula
          atividade={atividade}
          open={openModalAnexo}
          handleClose={handleCloseModalAnexo}
        />
      )}
      {atividade && openModalRespostas && (
        <ModalRespostasQuestionario
          realizarAtividadeId={atividade.realizaratividade_id}
          setOpen={setOpenModalRespostas}
          open={openModalRespostas}
        />
      )}
    </>
  );
}

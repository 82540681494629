import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { darken } from 'polished';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import { format, isValid } from 'date-fns';
// import html2pdf from 'html2pdf.js';
import html3pdf from 'html3pdf';
import Handlebars from 'handlebars';
import { isEmpty } from 'lodash';

import { toast } from 'react-toastify';
import history from '~/services/history';
import Breadcrumb from '~/components/CustomUI/CustomBreadcrumbs';
import DadosPessoais from '~/components/Contrato/DadosPessoais';
import DadosCurso from '~/components/Contrato/DadosCurso';
import TermosContrato from '~/components/Contrato/TermosContrato';
import Pagamento from '~/components/Contrato/Pagamento';
import ModalConclusao from '~/components/AceiteContrato/ModalConclusao';
import ModalAssinatura from '~/components/Contrato/TermosContrato/ModalAssinatura';
import {
  uploadContract,
  setLoading,
} from '~/store/modules/aceitecontrato/actions';
import Loading from '~/components/Loading';
import contratoTemplate from '~/assets/templates/contrato';
import { removeNotificacaoContratos } from '~/store/modules/notificacoes/actions';
import { request as requestContratos } from '~/store/modules/contratos/actions';
import { acceptTerms } from './services';

const labelsDadosCurso = {
  curso: 'Curso',
  tipo: 'Tipo de contrato',
  matricula: 'Data da matrícula',
  diasinicioaulas: 'Início das aulas',
  meses: 'Meses Aula',
  notacorte: 'Notas de Corte',
  multa: 'Multa Contratual',
  valorreposicao: 'Valor Reposição de Aula',
  cargahoraria: 'Carga Horária',
  frequenciaminima: 'Frequência Mínima',
};

const labelsDadosContratada = {
  cep: 'CEP',
  endereco: 'Endereço',
  bairro: 'Bairro',
  cidade: 'Cidade',
  estado: 'Estado',
  cnpj: 'CNPJ',
  inscricaoestadual: 'Inscrição Estadual',
  telefone: 'Telefone',
};

const labelsDadosPessoais = {
  nome: 'Nome completo',
  cgc_cpf: 'CPF',
  ie_rg: 'RG',
  nascimento: 'Data de nascimento',
  sexo: 'Sexo',
  estadocivil: 'Estado Civil',
  escolaridade: 'Escolaridade',
  profissao: 'Profissão',
  nomemae: 'Nome da mãe',
  nomepai: 'Nome do pai',
  celular: 'Celular',
  email: 'E-mail',
};

const labelsDadosEndereco = {
  logradouro: {
    label: 'Logradouro',
    colspan: 2,
  },
  numero: 'Número',
  complemento: 'Complemento',
  cep: 'CEP',
  bairro: 'Bairro',
  cidade: 'Cidade',
  estado: 'Estado',
};

const labelsValoresMatricula = {
  matricula_valor: 'Valor da matrícula',
  matricula_parcelas: 'Parcelas da matricula',
  matricula_vencimento: 'Vencimento da matrícula',
};

const labelsValoresMaterial = {
  materialdidatico_valor: 'Valor material didático',
  materialdidatico_parcelas: 'Parcelas material didático',
  materialdidatico_vencimento: 'Vencimento material didático',
};

const labelsTurmasMaterias = {
  turmas: {
    label: 'Turmas',
    colspan: 3,
  },
  livros: {
    label: 'Livros',
    colspan: 3,
  },
  materias: {
    label: 'Matérias',
    colspan: 3,
  },
};

const TIPO_MATRICULA = 'Matricula';
const TIPO_PARCELA = 'Parcela';
// const TIPO_MATERIAL = 'Material Didático';

export default function PendenteAceite({ match }) {
  const instituicao = useSelector((state) => state.instituicao.data);
  const profile = useSelector((state) => state.aluno.profile);
  const [activeStep, setActiveStep] = useState(0);
  const labelDadosCurso = useSelector((state) => state.funcionalidades.labels);

  const steps = [
    { label: 'Dados pessoais', descricao: 'Nome, documentos e endereços' },
    {
      label: `Dados de ${labelDadosCurso.contrato.singular}`,
      descricao: 'Informações sobre o curso do contrato',
    },
    {
      label: 'Termos do contrato',
      descricao: 'Confira as clausulas do seu contrato',
    },
    { label: 'Pagamento', descricao: 'Agora é hora de fazer o pagamento' },
  ];

  const ColorlibConnector = withStyles({
    alternativeLabel: {
      top: '20px',
      active: {
        color: `${instituicao.primary_color}`,
      },
    },
    active: {
      '& $line': {
        background: `${instituicao.primary_color}`,
      },
    },
    completed: {
      '& $line': {
        background: `${instituicao.primary_color}`,
      },
    },
    line: {
      height: 3,
      border: 0,
      backgroundColor: 'rgba(234, 236, 245, 0.95)',
      borderRadius: 1,
    },
  })(StepConnector);

  const useStyles = makeStyles((theme) => ({
    tituloFuncionalidade: {
      textTransform: 'uppercase',
      color: '#3E98C7',
      fontWeight: 'bold',
    },
    numeroContrato: {
      textTransform: 'uppercase',
      color: '#3E98C7',
      fontWeight: 'bold',
      lineHeight: 0.5,
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    captionHeader: {
      fontSize: '10px',
    },
    card: {
      marginTop: '15px',
    },
    nav: {
      padding: '24px',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    conteudo: {
      padding: '24px',
    },
    nextButton: {
      color: '#fff',
      padding: '5px 40px',
      borderRadius: '200px',
      background: `${activeStep === steps.length - 1 ? '#47D19D' : ''}`,
      '&:hover': {
        background: `${
          activeStep === steps.length - 1 ? darken(0.1, '#47D19D') : ''
        }`,
      },
    },
    backButton: {
      color: '#fff',
      padding: '5px 40px',
      borderRadius: '200px',
      marginRight: '10px',
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    iframe: {
      display: 'none',
      width: '210mm',
      minHeight: '297mm',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  }));

  const dispatch = useDispatch();
  const classes = useStyles();
  const unidade = useSelector((state) => state.unidade.data);
  const { dados: contratos } = useSelector((state) => state.contratos.data);
  const loading = useSelector((state) => state.aceitecontrato.loading);
  const contractUploaded = useSelector(
    (state) => state.aceitecontrato.contractUploaded
  );
  const loadingStep = useSelector((state) => state.aceitecontrato.loadingStep);
  const dadosPessoais = useSelector(
    (state) => state.aceitecontrato.dadosPessoais
  );
  const dadosTitular = useSelector(
    (state) => state.aceitecontrato.dadosTitular
  );
  const dadosCurso = useSelector((state) => state.aceitecontrato.dadosCurso);
  const dadosContratada = useSelector(
    (state) => state.aceitecontrato.dadosContratada
  );
  const termosUso = useSelector((state) => state.aceitecontrato.termosUso);
  const { contrato: id } = match.params;

  const [contrato] = useState(() => {
    return contratos.find((contratoFilter) => {
      return contratoFilter.contrato_id === parseInt(id, 10);
    });
  });

  const [openModalConclusao, setOpenModalConclusao] = useState(false);
  const [podeAvancar, setPodeAvancar] = useState(false);

  useEffect(() => {
    if (contrato) {
      if (!contrato.pendenteaceite) {
        history.push('/conteudos');
        return;
      }

      if (contrato.pdf) {
        setActiveStep(3);
      }
    }
  }, [contrato]); // eslint-disable-line

  const formatTerms = (terms) => {
    const BR_LINE_SPACE_LENGTH = 111;
    const termsArray = terms.split('\r\n');
    const termsDivided = [];
    let term = '';
    termsArray.forEach((paragraph) => {
      const pageLenghtAvailable = termsDivided.length === 0 ? 2500 : 3000;
      const isImg = paragraph.includes('<img');
      if (
        term.length + paragraph.length + BR_LINE_SPACE_LENGTH <
          pageLenghtAvailable ||
        isImg
      ) {
        term = term.concat(`${paragraph}<br>`);
      } else {
        termsDivided.push(term);
        term = `${paragraph}<br>`;
      }
    });

    if (term) {
      termsDivided.push(term);
    }

    let termsFormatted = '';
    termsDivided.forEach((page) => {
      const sanitizedPage = page.replace(/<br>/gi, '');
      if (!isEmpty(sanitizedPage)) {
        termsFormatted = termsFormatted.concat(
          `
            <div class="pageTermos">${page}</div>
            <div class="html2pdf__page-break"> </div>
          `
        );
      }
    });

    return termsFormatted;
  };

  const getOnlyFilledData = (data, fields) => {
    const fieldsHtml = [];
    const fieldsFilled = [];

    Object.keys(data).forEach((key) => {
      if (
        Number(data[key]) <= 0 ||
        isEmpty(data[key]) ||
        isEmpty(fields[key]) ||
        data[key] === 'Não Definido'
      ) {
        return;
      }

      if (key === 'turmas' && data[key] === '') {
        return;
      }

      if (fields[key]) {
        const colspan = fields[key].colspan || 1;
        fieldsFilled.push({ [key]: fields[key] });
        fieldsHtml.push({
          [key]: `<div class="dados-cell colspan-${colspan} value">${data[key]}</div>`,
        });
      }
    });

    let html = '';
    let newRow = true;
    const labels = [];

    // eslint-disable-next-line
    fieldsFilled.map((field, index) => {
      const key = Object.keys(field)[0];
      const value = field[key].label || field[key];
      const colspan = fields[key].colspan || 1;

      if (newRow) {
        html = html.concat(`<div class="dados">`);
        newRow = false;
      }

      html = html.concat(
        `<div class="dados-cell colspan-${colspan} label">${value}</div>`
      );

      if (
        (index + 1 + colspan) % 4 === 0 ||
        index === fieldsFilled.length - 1
      ) {
        newRow = true;
        html = html.concat('</div>');
        labels.push(html);
        html = '';
      }
    });

    newRow = true;
    html = '';
    const values = [];

    // eslint-disable-next-line
    fieldsHtml.map((field, index) => {
      const key = Object.keys(field)[0];
      const colspan = fields[key].colspan || 1;
      const value = field[key];

      if (newRow) {
        html = html.concat(`<div class="dados dadosValue">`);
        newRow = false;
      }

      html = html.concat(value);

      if ((index + 1 + colspan) % 4 === 0 || index === fieldsHtml.length - 1) {
        newRow = true;
        html = html.concat('</div>');
        values.push(html);
        html = '';
      }
    });

    let htmlFields = '';

    // eslint-disable-next-line
    values.map((_, index) => {
      htmlFields = htmlFields.concat(labels[index], values[index]);
    });

    return htmlFields;
  };

  const getHtmlDescontoParcelas = (parcelas, tipo) => {
    if (!parcelas || parcelas.length === 0) {
      return '';
    }

    const parcelasFiltered = parcelas.filter(
      (parcela) => parcela.tipo === tipo
    );

    if (parcelasFiltered.length === 0) {
      return '';
    }

    const label =
      parcelasFiltered[0].tipo === TIPO_MATRICULA
        ? 'da Matrícula'
        : parcelasFiltered[0].tipo === TIPO_PARCELA
        ? 'das Parcelas'
        : 'do Material Didático';

    let html = ``;
    if (parcelasFiltered[0] && parcelasFiltered[0].observacao) {
      html = `
          <div class="dados">
            <div class="dados-cell colspan-4 label">
              Descontos e Acréscimos ${label}
            </div>
          </div>
          <div class="dados dadosValue">
            <div class="dados-cell colspan-4 value">
            ${parcelasFiltered[0].observacao.replace(/\r/g, '<br />')}
            </div>
          </div>
        `;
    }

    return html;
  };

  const gerarContrato = async (clientSignature) => {
    try {
      dispatch(setLoading(true, 'Processando aceite...'));
      const newDadosCurso = { ...dadosCurso, cargahoraria: null };
      if (newDadosCurso.livros) {
        newDadosCurso.livros = newDadosCurso.livros
          .map((livro) => `${livro.livro}; `)
          .join('');
      }

      if (newDadosCurso.materias) {
        newDadosCurso.materias = newDadosCurso.materias
          .map((materia) => `${materia.materia}; `)
          .join('');
      }

      if (newDadosCurso.turmas) {
        if (newDadosCurso.turmas.length === 1) {
          newDadosCurso.turmas = newDadosCurso.turmas.filter(
            (turma) => turma.nome !== 'EAD'
          );
        }
        newDadosCurso.turmas = newDadosCurso.turmas
          .map((turma) => `${turma.nome}; `)
          .join('');
      }

      if (newDadosCurso.materialdidatico_valor) {
        newDadosCurso.materialdidatico_valor = new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }).format(newDadosCurso.materialdidatico_valor);
        newDadosCurso.materialdidatico_vencimento = format(
          new Date(newDadosCurso.materialdidatico_vencimento),
          'dd/MM/yyyy'
        );
      }

      if (newDadosCurso.matricula_valor) {
        newDadosCurso.matricula_valor = new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }).format(newDadosCurso.matricula_valor);
        const formattedDate = newDadosCurso.matricula_vencimento.split('/');
        newDadosCurso.matricula_vencimento = `${formattedDate[1]}-${formattedDate[0]}-${formattedDate[2]}`;
        if (isValid(new Date(newDadosCurso.matricula_vencimento))) {
          newDadosCurso.matricula_vencimento = format(
            new Date(newDadosCurso.matricula_vencimento),
            'dd/MM/yyyy'
          );
        } else {
          newDadosCurso.matricula_vencimento = '';
        }
      }

      if (contrato.cargahoraria) {
        newDadosCurso.cargahoraria = `${Math.trunc(
          contrato.cargahoraria / 60
        )} horas`;
      }

      if (dadosCurso.parcelas) {
        const parcelas = dadosCurso.parcelas.map((parcela) => {
          const valor = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(parcela.valor);

          const dateParcela = new Date(parcela.vencimento);
          const vencimento =
            isValid(dateParcela) &&
            format(new Date(parcela.vencimento), 'dd/MM/yyyy');

          let calculo = '';

          if (parcela.calculo) {
            calculo = new Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }).format(parcela.calculo);
          }
          return { ...parcela, valor, vencimento, calculo };
        });
        newDadosCurso.parcelas = parcelas;
      }

      const newDadosContratada = { ...dadosContratada };

      if (dadosContratada.logo) {
        const logoBase64 = await fetch(dadosContratada.logo).then((r) =>
          r.blob()
        );

        newDadosContratada.logo = URL.createObjectURL(logoBase64);
      }

      if (dadosContratada.assinatura1) {
        const assinatura1Base64 = await fetch(dadosContratada.assinatura1).then(
          (r) => r.blob()
        );

        newDadosContratada.assinatura1 = URL.createObjectURL(assinatura1Base64);
      }

      let newClientSignature = clientSignature;
      if (clientSignature.font) {
        newClientSignature = `<p class="${clientSignature.font
          .replace(/\s/gi, '-')
          .toLowerCase()}" style="color: ${clientSignature.penColor}">${
          dadosPessoais.nome
        }</p>`;
      } else {
        newClientSignature = `<img src="${clientSignature}" class="src-assinatura" />`;
      }

      const htmlHeaderMatriculaMaterial = `
    <div class="sessao">
        <p class="tituloSessao">Valores Matrícula e Material</p>
        <hr class="divisor" />
    </div>
    `;

      const data = {
        dadosContratada: newDadosContratada,
        htmlSessaoDadosContratada: getOnlyFilledData(
          newDadosContratada,
          labelsDadosContratada
        ),
        dadosTitular: dadosTitular || dadosPessoais,
        dadosAluno: dadosPessoais,
        htmlSessaoDadosTitular: getOnlyFilledData(
          dadosTitular || dadosPessoais,
          labelsDadosPessoais
        ),
        htmlSessaoDadosluno: getOnlyFilledData(
          dadosPessoais,
          labelsDadosPessoais
        ),
        htmlSessaoDadosEnderecoTitular: getOnlyFilledData(
          dadosTitular || dadosPessoais,
          labelsDadosEndereco
        ),
        htmlSessaoDadosEnderecoAluno: getOnlyFilledData(
          dadosPessoais,
          labelsDadosEndereco
        ),
        htmlSessaoDadosCurso: getOnlyFilledData(newDadosCurso, {
          ...labelsDadosCurso,
          curso: labelDadosCurso.contrato.singular,
        }),
        htmlSessaoValoresMatricula: getOnlyFilledData(
          newDadosCurso,
          labelsValoresMatricula
        ),
        htmlSessaoValoresMaterial: getOnlyFilledData(
          newDadosCurso,
          labelsValoresMaterial
        ),
        htmlSessaoTurmasMaterias: getOnlyFilledData(
          newDadosCurso,
          labelsTurmasMaterias
        ),
        htmlHeaderMatriculaMaterial:
          Boolean(newDadosCurso.matricula_valor) ||
          Boolean(newDadosCurso.materialdidatico_valor)
            ? htmlHeaderMatriculaMaterial
            : '',
        htmlDescontosParcelas: getHtmlDescontoParcelas(
          newDadosCurso.parcelas,
          TIPO_PARCELA
        ),
        tituloSessaoDadosCurso: `Dados do ${labelDadosCurso.contrato.singular}`,
        dadosCurso: newDadosCurso,
        termosUso: formatTerms(termosUso.termo_uso),
        dataAceite: format(new Date(), 'dd/MM/yyyy HH:mm:ss'),
        clientIP: profile.ip,
        clientSignature: newClientSignature,
        numeroContrato: contrato.numerocontrato,
      };

      const template = Handlebars.compile(contratoTemplate);

      const element = document.createElement('div');
      element.innerHTML = template(data);

      const opt = {
        margin: 10,
        image: { type: 'jpeg', quality: 1 },
        html2canvas: { scale: 2, dpi: 192, letterRendering: true },
        jsPDF: {
          unit: 'mm',
          format: 'a4',
          orientation: 'portrait',
        },
        pagebreak: { avoid: 'tr' },
      };

      // dispatch(setLoading(false, ''));
      // html3pdf().from(element).set(opt).toPdf().save();
      dispatch(setLoading(true, 'Gerando contrato...'));

      html3pdf()
        .from(element)
        .set(opt)
        .outputPdf('arraybuffer')
        .then((pdfBufer) => {
          dispatch(
            uploadContract(
              pdfBufer,
              contrato.contrato_id,
              dadosPessoais.pessoa_id,
              unidade.unidade_id
            )
          );
        });
    } catch (error) {
      console.log(error);
    }
  };

  async function handleNext() {
    if (activeStep === 3) {
      dispatch(setLoading(true, 'Processando aceite...'));
      await acceptTerms(contrato.contrato_id)
        .then(() => {
          setOpenModalConclusao(true);
          dispatch(removeNotificacaoContratos());
          dispatch(requestContratos());
          dispatch(setLoading(false, ''));
        })
        .catch(() => {
          dispatch(setLoading(false, ''));
          toast.error('Falha ao aceitar contrato, favor contactar o suporte.');
        });
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  }

  useEffect(() => {
    if (contractUploaded) {
      handleNext();
    }
  }, [contractUploaded]); // eslint-disable-line

  function handleBack() {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }

  function getConteudo() {
    switch (activeStep) {
      case 1:
        return <DadosCurso contrato={contrato} />;
      case 2:
        return (
          <TermosContrato
            contrato={contrato}
            accepted={podeAvancar}
            setAccepted={setPodeAvancar}
          />
        );
      case 3:
        return <Pagamento contrato={contrato} />;
      default:
        return <DadosPessoais contrato={contrato} />;
    }
  }

  if (loading) {
    return <Loading description={loadingStep} />;
  }

  return (
    <>
      <Breadcrumb categorias={['Contrato']} funcionalidade="Aceite" />
      <div className={classes.header}>
        <Typography variant="h6" className={classes.tituloFuncionalidade}>
          Matrícula
        </Typography>
        <div>
          <Typography variant="caption" className={classes.captionHeader}>
            N° DO CONTRATO
          </Typography>
          {contrato && (
            <Typography variant="h6" className={classes.numeroContrato}>
              {contrato.numerocontrato}
            </Typography>
          )}
        </div>
      </div>
      <Card className={classes.card}>
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          connector={<ColorlibConnector />}
        >
          {steps.map((step) => (
            <Step key={step.label}>
              <StepLabel>
                {step.label}
                <Typography variant="caption" component="p">
                  {step.descricao}
                </Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        <div className={classes.conteudo}>{contrato && getConteudo()}</div>
        <div className={classes.nav}>
          {activeStep > 0 && activeStep < 3 && (
            <Button
              onClick={handleBack}
              className={classes.backButton}
              variant="contained"
              color="secondary"
            >
              Voltar
            </Button>
          )}
          {activeStep === 2 && (
            <ModalAssinatura
              termosConfirmados={podeAvancar}
              gerarContrato={gerarContrato}
              contratante={dadosPessoais}
            />
          )}
          {activeStep !== 2 && (
            <Button
              variant="contained"
              onClick={handleNext}
              className={classes.nextButton}
              color="primary"
            >
              {activeStep === steps.length - 1 ? 'Concluir' : 'Avançar'}
            </Button>
          )}
        </div>
      </Card>
      {openModalConclusao && (
        <ModalConclusao
          open={openModalConclusao}
          setOpen={setOpenModalConclusao}
          contrato={contrato}
        />
      )}
    </>
  );
}

PendenteAceite.propTypes = {
  match: PropTypes.shape().isRequired,
};
